@import "../../scss/media-query";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e0e0e;
  z-index: 100000;
  overflow: hidden;

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .back-button {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      z-index: 13;

      img {
        height: 38px;
        object-fit: contain;

        &::selection {
          color: transparent;
          background-color: transparent;
        }
      }

      @include large-devices-up {
        top: 4rem;
        left: 5rem;

        img {
          height: 52px;
        }
      }

      @include extra-large-devices-up {
        top: 4rem;
        left: 5rem;
      }

      @include extra-extra-large-devices-up {
        top: 4rem;
        left: 5rem;
      }
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 12;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5.5rem 2rem 3rem 2rem;

      .image-wrapper {
        width: 120px;
        height: 120px;
        min-width: 120px;
        min-height: 120px;
        position: relative;
        z-index: 14;
        margin-bottom: 1rem;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          pointer-events: none;
          z-index: 16;
        }

        .center-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 26px;
          height: 26px;
          transform: translateX(-50%) translateY(-50%);
          object-fit: contain;
          pointer-events: none;
          z-index: 16;
        }
      }

      .main-title {
        color: #fff;
        font-size: 1.8rem;
        font-weight: 900;
        font-family: "Raleway";
        text-align: center;
        margin-bottom: 1rem;
      }

      .main-label {
        font-family: "Raleway";
        font-weight: 400;
        font-size: 0.975rem;
        line-height: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
        background: linear-gradient(
            267.99deg,
            rgba(76, 54, 132, 0.3) 6.85%,
            rgba(234, 30, 37, 0.3) 47.26%,
            rgba(245, 202, 29, 0.3) 86.56%
          ),
          rgba(255, 255, 255, 0.5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        span {
          font-weight: 700;
        }
      }

      .label {
        font-size: 1rem;
        line-height: 1.6rem;
        margin-bottom: 1.5rem;
        color: #fff;
        font-family: "Raleway";
      }

      .button {
        height: 50px;
        min-height: 50px;
        width: 220px;
        border-radius: 34px;
        background-color: #4c3684;
        box-shadow: 8px 12px 12px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        border: 1px solid #4c3684;
        transition: background-color 0.2s ease-in-out;
        margin: 1rem 0;

        p {
          font-family: "Montserrat", sans-serif;
          color: #fff;
          font-size: 1rem;
          text-align: center;
          font-weight: 700;
        }

        @include extra-large-devices-up {
          height: 60px;
          min-height: 54px;
          width: 250px;
        }

        &:hover {
          background-color: transparent;
        }
      }

      @include medium-devices-up {
        padding: 4.5rem 10rem 3rem 10rem;

        .label {
          font-size: 1rem;
          line-height: 1.6rem;
        }

        .main-title {
          font-size: 2.4rem;
        }
      }

      @include large-devices-up {
        padding: 4.5rem 12rem 3rem 12rem;
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      pointer-events: none;
      object-fit: cover;
    }
  }
}
