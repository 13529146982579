@import "../../scss/media-query";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e0e0e;
  z-index: 100000;
  overflow: hidden;

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .back-button {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      z-index: 13;

      img {
        height: 38px;
        object-fit: contain;

        &::selection {
          color: transparent;
          background-color: transparent;
        }
      }

      @include large-devices-up {
        top: 4rem;
        left: 5rem;

        img {
          height: 52px;
        }
      }

      @include extra-large-devices-up {
        top: 4rem;
        left: 5rem;
      }

      @include extra-extra-large-devices-up {
        top: 4rem;
        left: 5rem;
      }
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 12;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5.5rem 2rem 3rem 2rem;

      .main-title {
        color: #fff;
        font-size: 1.8rem;
        font-weight: 900;
        font-family: "Raleway";
        text-align: center;
        margin-bottom: 1rem;
      }

      .main-label {
        font-family: "Raleway";
        font-weight: 400;
        font-size: 0.975rem;
        text-align: center;
        margin-bottom: 1rem;
        background: linear-gradient(
            267.99deg,
            rgba(76, 54, 132, 0.3) 6.85%,
            rgba(234, 30, 37, 0.3) 47.26%,
            rgba(245, 202, 29, 0.3) 86.56%
          ),
          rgba(255, 255, 255, 0.5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        span {
          font-weight: 700;
        }
      }

      .title {
        font-weight: 600;
      }

      .label {
        font-size: 0.95rem;
        line-height: 1.4rem;
        margin-bottom: 1.25rem;
        color: #fff;
        font-family: "Raleway";
        width: 100%;
        text-align: left;

        span {
          display: block;
          padding-left: 1rem;
          margin-top: 0.5rem;
          position: relative;

          div {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }
      }

      @include medium-devices-up {
        padding: 4.5rem 10rem 3rem 10rem;

        .label {
          font-size: 1rem;
          line-height: 1.6rem;
        }

        .main-title {
          font-size: 2.4rem;
        }
      }

      @include large-devices-up {
        padding: 4.5rem 12rem 3rem 12rem;
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      pointer-events: none;
      object-fit: cover;
    }
  }
}
