* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
  background-color: #0e0e0e;
  // background-color: red;
  overflow: hidden;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;

  &:active {
    border: none;
    outline: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  background-color: transparent;
  border: none;
  outline: none;
  background-color: transparent;

  &:active {
    border: none;
    outline: none;
  }
}
