@import "../../scss/media-query";

.contact-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0e0e0e;
    z-index: 100000;
    overflow: hidden;

    .container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .toast {
            position: fixed;
            top: 1.5rem;
            right: 1.5rem;
            display: flex;
            flex-direction: column;
            z-index: 10000000;
            background-color: #0e0e0e;
            border: 1px solid #202328;
            // border-radius: 12px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .strip {
                width: 100%;
                height: 2px;
                background-color: red;
                margin-bottom: 1rem;
            }

            .title {
                font-size: 0.875rem;
                font-weight: 600;
                color: #fff;
                margin-bottom: 0.2rem;
                font-family: "Montserrat";
                padding: 0 1rem;
            }

            .description {
                font-size: 0.875rem;
                color: #fff;
                margin-top: 0.2rem;
                font-family: "Montserrat";
                padding: 0 1rem 1rem 1rem;
            }

            @include large-devices-up {
                top: 2rem;
                right: 2rem;

                .title {
                    padding: 0 1.5rem;
                }

                .description {
                    padding: 0 1.5rem 1rem 1.5rem;
                }
            }
        }

        .back-button {
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            z-index: 13;

            img {
                height: 38px;
                object-fit: contain;

                &::selection {
                    color: transparent;
                    background-color: transparent;
                }
            }

            @include large-devices-up {
                top: 4rem;
                left: 5rem;

                img {
                    height: 52px;
                }
            }

            @include extra-large-devices-up {
                top: 4rem;
                left: 5rem;
            }

            @include extra-extra-large-devices-up {
                top: 4rem;
                left: 5rem;
            }
        }

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 12;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 4.5rem;
            padding-bottom: 3rem;

            .main-title {
                color: #fff;
                font-size: 1.8rem;
                font-weight: 900;
                font-family: "Raleway";
                text-align: center;
                margin-bottom: 1rem;
            }

            .main-label {
                font-family: "Raleway";
                font-weight: 500;
                font-size: 0.975rem;
                text-align: center;
                width: 90%;
                margin-bottom: 1rem;
                background: linear-gradient(
                        267.99deg,
                        rgba(76, 54, 132, 0.3) 6.85%,
                        rgba(234, 30, 37, 0.3) 47.26%,
                        rgba(245, 202, 29, 0.3) 86.56%
                    ),
                    rgba(255, 255, 255, 0.5);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            .button {
                border-radius: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 170px;
                height: 54px;
                min-height: 54px;
                background: #ffffff;
                box-shadow: 8px 12px 12px rgba(0, 0, 0, 0.15);
                margin-top: 0rem;

                p {
                    text-transform: uppercase;
                    color: #2f3a95;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: center;
                    font-family: "Montserrat", sans-serif;
                }

                &:disabled {
                    opacity: 0.5;
                }
            }

            .text-input-wrapper {
                margin-bottom: 1.5rem;
                width: 90%;

                .label {
                    text-transform: uppercase;
                    font-weight: 700;
                    color: #fff;
                    font-family: "Montserrat";
                    margin-bottom: 0.5rem;
                    font-size: 0.875rem;
                }

                .text-input-container {
                    width: 100%;
                    height: 54px;
                    border-radius: 16.5px;
                    border: 2.5px solid rgba(255, 255, 255, 0.2);
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    position: relative;
                    padding: 0 1.5rem;

                    .placeholder {
                        width: 100%;
                        font-size: 0.875rem;
                        color: rgba(255, 255, 255, 0.5);
                        text-transform: uppercase;
                        font-family: "Montserrat";
                    }

                    .options {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        .option {
                            background-color: grey;
                            padding: 0 0.5rem;
                            border-radius: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 0.5rem;

                            p {
                                color: #fff;
                                font-size: 0.75rem;
                                font-family: "Montserrat";
                                text-align: center;
                            }

                            .remove-button {
                                width: 22px;
                                height: 22px;
                                min-width: 22px;
                                min-height: 22px;
                                border-radius: 50%;
                                background-color: darkslategray;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 0.5rem;
                            }
                        }
                    }

                    .value {
                        width: 100%;
                        font-family: "Montserrat";
                        font-size: 0.875rem;
                        color: #fff;
                    }

                    input {
                        flex: 1;
                        font-family: "Montserrat";
                        font-size: 0.875rem;
                        color: #fff;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.5);
                            text-transform: uppercase;
                        }
                    }

                    .icon-button {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        transform: translateX(1rem);

                        img {
                            width: 10px;
                            height: 10px;
                            object-fit: contain;
                        }
                    }

                    .dropdown {
                        position: absolute;
                        border: 1px solid #202328;
                        border-radius: 24px;
                        top: calc(100% + 0.5rem);
                        left: -0.5rem;
                        width: calc(100% + 1rem);
                        background-color: #0e0e0e;
                        z-index: 1000000;
                        padding: 0 1rem;
                        box-shadow: 183px 193px 80px rgba(0, 0, 0, 0);

                        .item {
                            width: 100%;
                            padding: 1rem 1.5rem;
                            font-size: 0.975rem;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #202328;

                            p {
                                width: 100%;
                                color: #fff;
                                font-weight: 400;
                                font-family: "Montserrat";
                            }
                        }

                        @include medium-devices-up {
                            left: -1rem;
                            width: calc(100% + 2rem);
                        }
                    }
                }

                .textarea-container {
                    width: 100%;
                    height: 180px;
                    border-radius: 16.5px;
                    border: 2.5px solid rgba(255, 255, 255, 0.2);
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;

                    textarea {
                        width: 100%;
                        height: 100%;
                        padding: 1rem 1.5rem;
                        font-family: "Montserrat";
                        font-size: 0.875rem;
                        color: #fff;
                        vertical-align: top;
                        resize: none;

                        &::placeholder {
                            color: rgba(255, 255, 255, 0.5);
                            text-transform: uppercase;
                        }
                    }
                }

                @include medium-devices-up {
                    width: 80%;
                }

                @include large-devices-up {
                    width: 70%;
                }

                @include extra-large-devices-up {
                    width: 60%;
                }

                @include extra-extra-large-devices-up {
                    width: 55%;
                }
            }

            .row {
                width: 90%;
                display: flex;
                flex-direction: column;
                // margin-bottom: 1.5rem;

                @include medium-devices-up {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 80%;
                    margin-bottom: 1.5rem;
                }

                @include large-devices-up {
                    width: 70%;
                }

                @include extra-large-devices-up {
                    width: 60%;
                }

                @include extra-extra-large-devices-up {
                    width: 55%;
                }
            }

            .col {
                width: 100%;

                .text-input-wrapper {
                    width: 100%;
                }

                @include medium-devices-up {
                    width: calc((100% - 1rem) * 0.5);

                    .text-input-wrapper {
                        margin-bottom: 0rem;
                    }
                }
            }

            @include large-devices-up {
                .main-title {
                    font-size: 2.4rem;
                }

                .main-label {
                    width: 40%;
                }
            }
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            pointer-events: none;
            object-fit: cover;
        }
    }
}
