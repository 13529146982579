@import "../../scss/media-query";

.container {
    width: 100%;
    height: 100%;
    background-color: #0e0e0e;
    position: relative;
    overflow-x: hidden;
    // overflow: hidden;

    .mask {
        width: fit-content;
        overflow: hidden;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .background-shape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        pointer-events: none;
        opacity: 0.6;
        object-fit: cover;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: fit-content;
        // min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // overflow-y: scroll;
    }

    .gradient-1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 35vh;
        z-index: 6;
        object-fit: contain;
        transform: scale(2) translateY(-24px);
        pointer-events: none;
    }

    .shape-1 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10vw;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .shape-2 {
        position: absolute;
        top: 56%;
        left: 0;
        width: 100%;
        z-index: 7;
        object-fit: contain;
        pointer-events: none;
        opacity: 0.8;
    }

    .shape-3 {
        position: absolute;
        top: 25vh;
        left: 0;
        width: 20vw;
        z-index: 7;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-4 {
        position: absolute;
        top: 95vh;
        left: -20vw;
        width: 30vw;
        height: 30vw;
        border-radius: 50%;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-6 {
        position: absolute;
        top: 14vh;
        left: -18vw;
        width: 26vw;
        height: 26vw;
        border-radius: 50%;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .shape-4 {
        position: absolute;
        top: 30vh;
        right: 0;
        width: 26vw;
        z-index: 7;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-5 {
        position: absolute;
        top: 48vh;
        right: -12vw;
        width: 30vw;
        height: 30vw;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-diamond-1 {
        position: absolute;
        top: 138vh;
        right: 17%;
        width: 64px;
        height: 64px;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-diamond-2 {
        position: absolute;
        top: 162vh;
        right: 28%;
        width: 32px;
        height: 32px;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-diamond-3 {
        position: absolute;
        top: 132vh;
        left: 22%;
        width: 24px;
        height: 24px;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-diamond-4 {
        position: absolute;
        top: 146vh;
        left: 7%;
        width: 24px;
        height: 24px;
        z-index: 6;
        object-fit: contain;
        pointer-events: none;
    }

    .gradient-button-1,
    .gradient-button-2,
    .gradient-button-3,
    .gradient-button-4 {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        img {
            width: 18px;
            height: 18px;
        }
    }

    .gradient-button-1 {
        left: -28px;
        top: 8%;

        @include medium-devices-up {
            left: 8%;
            top: 8%;
        }
    }

    .gradient-button-2 {
        left: 12%;
        top: 10%;
        width: 60px;
        height: 60px;

        @include medium-devices-up {
            left: 22%;
            top: 12%;
            width: 80px;
            height: 80px;
        }
    }

    .gradient-button-3 {
        right: 12%;
        top: 10%;
        width: 40px;
        height: 40px;

        img {
            width: 12px;
            height: 12px;
        }

        @include medium-devices-up {
            right: 22%;
            top: 11%;
            width: 50px;
            height: 50px;

            img {
                width: 14px;
                height: 14px;
            }
        }
    }

    .gradient-button-4 {
        right: -26px;
        top: 8%;

        @include medium-devices-up {
            right: 3%;
            top: 7%;
        }
    }

    .dot-1,
    .dot-2,
    .dot-3,
    .dot-4,
    .dot-5,
    .dot-6,
    .dot-7,
    .dot-8,
    .dot-9,
    .dot-10,
    .dot-11 {
        position: absolute;
        pointer-events: none;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #939393;
        z-index: 10;
    }

    .dot-1 {
        width: 4px;
        height: 4px;
        top: 10vh;
        left: 35%;
    }

    .dot-2 {
        width: 2px;
        height: 2px;
        top: 5vh;
        left: 50%;
    }

    .dot-3 {
        width: 4px;
        height: 4px;
        top: 7vh;
        left: 54%;
    }

    .dot-4 {
        width: 2px;
        height: 2px;
        top: 16.5vh;
        left: 47.5%;
    }

    .dot-5 {
        width: 4px;
        height: 4px;
        top: 41vh;
        left: 11%;
    }

    .dot-6 {
        width: 2px;
        height: 2px;
        top: 44vh;
        left: 15%;
    }

    .dot-7 {
        width: 2px;
        height: 2px;
        top: 14vh;
        right: 30%;
    }

    .dot-8 {
        width: 4px;
        height: 4px;
        top: 10vh;
        right: 28%;
    }

    .dot-9 {
        width: 2px;
        height: 2px;
        top: 52vh;
        right: 36%;
    }

    .dot-10 {
        width: 4px;
        height: 4px;
        top: 57vh;
        right: 34%;
    }

    .dot-11 {
        width: 4px;
        height: 4px;
        top: 37vh;
        right: 17%;
    }

    .logo {
        height: 34px;
        object-fit: contain;
        z-index: 10;
        pointer-events: none;

        &::selection {
            color: none;
            background-color: none;
        }

        @include medium-devices-up {
            height: 44px;
        }
    }

    .button {
        height: 50px;
        min-height: 50px;
        width: 220px;
        border-radius: 34px;
        // background: linear-gradient(
        //     0deg,
        //     rgba(255, 255, 255, 0.1),
        //     rgba(255, 255, 255, 0.1)
        //   ),
        //   linear-gradient(180deg, #4c3684 0%, #2f3a95 100.02%), #ffffff;
        background-color: #4c3684;
        box-shadow: 8px 12px 12px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        border: 1px solid #4c3684;
        transition: background-color 0.2s ease-in-out;

        p {
            font-family: "Montserrat", sans-serif;
            color: #fff;
            font-size: 1rem;
            text-align: center;
            font-weight: 700;
        }

        @include extra-large-devices-up {
            height: 60px;
            min-height: 54px;
            width: 250px;
        }

        &:hover {
            background-color: transparent;
        }
    }

    .main-title {
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 700;
        // color: #fff;
        // font-family: "Montserrat", sans-serif;
        z-index: 100;
        width: 90%;

        .gradient {
            font-weight: 700;
            font-family: "Noto Serif", serif;
            background: linear-gradient(
                267.99deg,
                #4c3684 6.85%,
                #ea1e25 47.26%,
                #f5ca1d 86.56%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        .white {
            color: #fff;
        }

        @include medium-devices-up {
            width: 75%;
            font-size: 1.5rem;
            line-height: 2.2rem;
        }

        @include extra-large-devices-up {
            width: 60%;
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    }

    .main-label {
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.3rem;
        width: 90%;
        color: #fff;
        font-family: "Raleway", sans-serif;
        z-index: 100;

        @include medium-devices-up {
            width: 75%;
            font-size: 1rem;
            line-height: 1.4rem;
        }

        @include extra-large-devices-up {
            width: 60%;
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
    }

    .request-quote {
        margin-top: 2rem;

        // @include medium-devices-up {
        //   margin-top: 2rem;
        // }
    }

    .image-1 {
        position: relative;
        width: 80%;
        aspect-ratio: 2.23144;
        margin-top: 10rem;
        margin-bottom: 3rem;
        z-index: 10;

        &::selection {
            color: transparent;
            background-color: transparent;
        }

        .image {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 10;
            pointer-events: none;
            border-radius: 12px;
        }

        .bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: scale(1.35);
            z-index: 2;
            pointer-events: none;
        }

        .star-circle-1 {
            position: absolute;
            left: 24%;
            top: -14%;
            width: 18%;
            height: 18%;
            object-fit: contain;
            z-index: 4;
            pointer-events: none;
        }

        .star-circle-2 {
            position: absolute;
            right: -8%;
            top: -14%;
            width: 21%;
            height: 21%;
            object-fit: contain;
            z-index: 4;
            pointer-events: none;
        }

        .star-circle-3 {
            position: absolute;
            left: -15%;
            bottom: -9%;
            width: 21%;
            height: 21%;
            object-fit: contain;
            z-index: 4;
            pointer-events: none;
        }

        .star-circle-4 {
            position: absolute;
            left: 40%;
            bottom: -15%;
            width: 21%;
            height: 21%;
            object-fit: contain;
            z-index: 4;
            pointer-events: none;
        }

        .gradient-circle-1,
        .gradient-circle-2,
        .gradient-circle-3,
        .gradient-circle-4 {
            position: absolute;
            pointer-events: none;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #232841;
            z-index: 8;
        }

        .gradient-circle-1 {
            left: 40%;
            top: -17%;
        }

        .gradient-circle-2 {
            right: -10%;
            top: -15%;
        }

        .gradient-circle-3 {
            left: -16%;
            bottom: -10%;
        }

        .gradient-circle-4 {
            left: 39%;
            bottom: -16%;
        }

        @include medium-devices-up {
            margin-top: 12rem;
            width: 60%;
        }

        @include extra-large-devices-up {
            width: 50%;

            .gradient-circle-1,
            .gradient-circle-2,
            .gradient-circle-3,
            .gradient-circle-4 {
                width: 40px;
                height: 40px;
            }
        }
    }

    .two {
        position: absolute;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        pointer-events: none;
        z-index: 10;
        background: linear-gradient(
            267.99deg,
            rgba(76, 54, 132, 0.5),
            rgba(234, 30, 37, 0.5),
            rgba(245, 202, 29, 0.5)
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 10px transparent;
        color: #181529;

        font-size: 320px;
        line-height: 540px;
        top: 68vh;
        left: -(calc(320px / 2.5));

        &::selection {
            color: transparent;
            background-color: transparent;
        }

        @include medium-devices-up {
            font-size: 420px;
            line-height: 640px;
            top: 68vh;
            left: -(calc(420px / 4));
        }

        @include extra-large-devices-up {
            font-size: 560px;
            line-height: 836px;
            top: 54vh;
            left: -(calc(560px / 4.5));
        }
    }

    .map {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 15%;
        width: 100%;
        pointer-events: none;
        object-fit: cover;
        z-index: 5;

        @include medium-devices-up {
            width: 100%;
            height: 25%;
        }

        @include extra-large-devices-up {
            height: fit-content;
            width: 100%;
            object-fit: contain;
        }
    }

    .n-two {
        position: absolute;
        top: 55%;
        left: -(calc(560px / 1.4));
        pointer-events: none;

        p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 560px;
            line-height: 420px;
            background: linear-gradient(
                267.99deg,
                rgba(76, 54, 132, 0.2),
                rgba(234, 30, 37, 0.2),
                rgba(245, 202, 29, 0.2)
            );
            -webkit-background-clip: text;
            -webkit-text-stroke: 10px transparent;
            color: #181529;
            // transform: translateY(-300px);
            transform: rotate(-90deg);
        }

        &::selection {
            color: transparent;
            background-color: transparent;
        }
    }

    .en {
        position: absolute;
        bottom: 0;
        right: -200px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 580px;
        pointer-events: none;
        line-height: 520px;
        z-index: 10;
        background: linear-gradient(
            267.99deg,
            rgba(76, 54, 132, 0.2),
            rgba(234, 30, 37, 0.2),
            rgba(245, 202, 29, 0.2)
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 10px transparent;
        color: #181529;
        transform: rotate(-90deg) translateX(-50px);

        &::selection {
            color: transparent;
            background-color: transparent;
        }
    }

    .n {
        position: absolute;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        pointer-events: none;
        z-index: 10;
        background: linear-gradient(
            267.99deg,
            rgba(76, 54, 132, 0.2),
            rgba(234, 30, 37, 0.2),
            rgba(245, 202, 29, 0.2)
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 10px transparent;
        color: #181529;

        font-size: 420px;
        line-height: 640px;
        top: 15%;
        right: -(calc(420px / 2.5));

        &::selection {
            color: transparent;
            background-color: transparent;
        }

        @include medium-devices-up {
            font-size: 420px;
            line-height: 640px;
            top: 55%;
            right: -(calc(420px / 4));
        }

        @include extra-large-devices-up {
            font-size: 560px;
            top: 12%;
            right: -(calc(560px / 3));
            line-height: 836px;
        }
    }

    .section {
        .top {
            display: flex;
            align-items: center;

            .line {
                width: 50px;
                height: 2px;
                margin-right: 0.75rem;
                background: linear-gradient(
                        267.99deg,
                        rgba(76, 54, 132, 0.4) 6.85%,
                        rgba(234, 30, 37, 0.4) 47.26%,
                        rgba(245, 202, 29, 0.4) 86.56%
                    ),
                    linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.3),
                        rgba(255, 255, 255, 0.3)
                    );
            }

            p {
                font-size: 1rem;
                font-family: "Montserrat";
                font-weight: 700;
                background: linear-gradient(
                        267.99deg,
                        rgba(76, 54, 132, 0.4) 6.85%,
                        rgba(234, 30, 37, 0.4) 47.26%,
                        rgba(245, 202, 29, 0.4) 86.56%
                    ),
                    linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.3),
                        rgba(255, 255, 255, 0.3)
                    ),
                    linear-gradient(180deg, #4c3684 0%, #2f3a95 100.02%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }

        .title {
            font-weight: 900;
            color: #fff;
            font-size: 1.6rem;
            font-family: "Montserrat";

            span {
                font-family: "Noto Serif";
            }
        }

        .description {
            font-size: 0.875rem;
            line-height: 1.3rem;
            color: #fff;
            font-family: "Raleway";

            span {
                font-weight: 500;
                text-decoration: underline;
            }
        }

        @include medium-devices-up {
            .title {
                font-size: 1.4rem;
            }

            .description {
                font-size: 0.875rem;
                line-height: 1.3rem;
            }
        }

        @include extra-large-devices-up {
            .title {
                font-size: 2.6rem;
            }

            .description {
                font-size: 1rem;
                line-height: 1.8rem;
            }
        }
    }

    .tech-support {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        z-index: 100;
        position: relative;

        .gradient-2 {
            position: absolute;
            top: -10%;
            left: 50%;
            width: 150%;
            height: 150%;
            z-index: 6;
            object-fit: contain;
            opacity: 0.1;
            pointer-events: none;
            // background-color: red;
        }

        .left-container {
            .image {
                width: 200px;
                object-fit: contain;
            }
        }

        @include medium-devices-up {
            width: 70%;
            flex-direction: row;

            .gradient-2 {
                top: 8%;
                left: 0%;
                width: 65%;
                height: 65%;
                opacity: 0.7;
            }

            .left-container {
                margin-right: 1rem;

                .image {
                    width: 160px;
                }
            }
        }

        @include large-devices-up {
            width: 55%;
        }

        @include extra-large-devices-up {
            width: 50%;
            margin-top: 4rem;

            .left-container {
                margin-right: 2rem;

                .image {
                    width: 270px;
                }
            }
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-wrapper {
            width: 120px;
            height: 120px;
            position: relative;

            .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                pointer-events: none;
            }

            .center-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 26px;
                height: 26px;
                transform: translateX(-50%) translateY(-50%);
                object-fit: contain;
                pointer-events: none;
            }
        }

        .title {
            font-size: 1.2rem;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 900;
            text-align: center;
            color: #fff;
            white-space: pre-line;
        }

        .label {
            font-size: 0.875rem;
            line-height: 1.4rem;
            font-family: "Raleway";
            color: rgba(255, 255, 255, 0.7);
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .button {
            width: 120px;
            height: 34px;
            background-color: #ffffff;
            border: 1px solid #ffffff;
            box-shadow: 8px 12px 12px rgba(0, 0, 0, 0.15);

            p {
                color: #2f3a95;
                transition: color 0.2s ease-in-out;
            }

            &:hover {
                background-color: transparent;

                p {
                    color: #ffffff;
                }
            }
        }

        @include medium-devices-up {
            .image-wrapper {
                width: 90px;
                height: 90px;
            }

            .title {
                font-size: 1rem;
            }

            .button {
                width: 160px;
                height: 54px;
            }
        }

        @include extra-large-devices-up {
            .image-wrapper {
                width: 150px;
                height: 150px;
            }

            .title {
                font-size: 1.5rem;
            }

            .button {
                width: 160px;
                height: 54px;
            }
        }
    }

    .ultimate-solution {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 100%;
        row-gap: 3rem;
        width: 90%;
        z-index: 100;
        position: relative;

        .gradient-3 {
            position: absolute;
            top: 0%;
            left: -10%;
            width: 120%;
            z-index: 6;
            object-fit: contain;
            pointer-events: none;
        }

        .section {
            margin-bottom: 1.5rem;
        }

        .item {
            min-width: 18.5vw;

            .button {
                margin-top: 0.5rem;
            }
        }

        @include medium-devices-up {
            margin-top: 8rem;
            grid-template-columns: 1fr 24vw 24vw;
            column-gap: calc((100% - (4 * 24vw)) / 2);
            width: 85%;

            .gradient-3 {
                top: -35%;
                left: 10%;
                width: 60vw;
                height: 60vw;
            }

            .section {
                padding-right: 1rem;
            }

            .item {
                min-width: 24vw;

                .button {
                    margin-top: 0.5rem;
                }
            }
        }

        @include extra-large-devices-up {
            margin-top: 10rem;
            grid-template-columns: 1fr 17.25vw 17.25vw;
            column-gap: calc((100% - (4 * 17.25vw)) / 3);
            width: 75%;

            .section {
                padding-right: 3rem;
            }

            .item {
                min-width: 17.25vw;

                .button {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .services {
        margin-top: 3rem;
        display: grid;
        width: 80%;
        grid-template-columns: 100%;
        row-gap: 3.5rem;
        z-index: 100;

        .item {
            min-width: 18.5vw;
        }

        @include medium-devices-up {
            margin-top: 8rem;
            width: 75%;
            grid-template-columns: repeat(2, 35vw);
            column-gap: calc((100% - (2 * 35vw)));
            row-gap: 34px;

            .item {
                min-width: 35vw;
            }
        }

        @include extra-large-devices-up {
            width: 75%;
            grid-template-columns: repeat(4, 17.25vw);
            column-gap: calc((100% - (4 * 17.25vw)) / 3);

            .item {
                min-width: 17.25vw;
            }
        }
    }

    .footer {
        height: 70vh;
        width: 100%;
        position: relative;
        z-index: 100;
        // background-color: red;

        .background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(
                296.08% 56.54% at 55.52% 56.54%,
                #443889 0%,
                rgba(68, 56, 137, 0) 100%
            );
            opacity: 0.2;
            z-index: 8;
        }

        .footer-content {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 5rem;
            z-index: 12;

            .contact-container {
                width: calc(356px * 0.95);
                height: calc(206px * 0.95);
                position: relative;
                margin-left: 24px;

                .bg-image {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    z-index: 2;
                    transform: scale(1.3);
                    pointer-events: none;
                    &::selection {
                        color: transparent;
                        background-color: transparent;
                    }
                }

                .contact {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    padding: 1.5rem 1rem 2rem 3rem;
                    z-index: 4;
                    &::selection {
                        color: transparent;
                        background-color: transparent;
                    }

                    .row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1rem;

                        .icon {
                            width: 16px;
                            height: 16px;
                            object-fit: contain;
                            margin-right: 0.5rem;
                        }

                        p {
                            font-family: "Raleway";
                            font-size: 1rem;
                            color: rgba(255, 255, 255, 0.7);
                            margin-bottom: 0.2rem;
                        }
                    }
                }

                @include medium-devices-up {
                    width: calc(356px * 0.8);
                    height: calc(206px * 0.8);
                }
            }

            .diamond {
                width: 26px;
                height: 26px;
                object-fit: contain;
            }

            .en2-logo {
                height: 16px;
                object-fit: contain;
            }
        }

        .bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            left: 10%;
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 2rem;
            justify-content: space-between;
            z-index: 20;

            .row {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1.5rem;
            }

            .made-with-love {
                width: 80vw;
                max-height: 46px;
                margin: 1.5rem 0;
                object-fit: contain;
                pointer-events: none;

                &::selection {
                    color: transparent;
                    background-color: transparent;
                }
            }

            p {
                text-transform: uppercase;
                font-size: 0.825rem;
                color: #fff;
                font-family: "Poppins";
                letter-spacing: 0.15rem;
                text-align: center;
            }
        }

        @include medium-devices-up {
            .footer-content {
                .contact-container {
                    width: calc(356px * 0.9);
                    height: calc(206px * 0.9);

                    .contact {
                        padding: 1.5rem 1rem 2rem 3rem;
                    }
                }
            }

            .bottom {
                flex-direction: row;
                left: 1rem;
                width: calc(100% - 2rem);

                .row {
                    flex-direction: row;
                    gap: 0.5rem;
                }

                .made-with-love {
                    width: 28vw;
                    margin: 0 0 0.1rem 6rem;
                }

                p {
                    font-size: 0.675rem;
                }
            }
        }

        @include large-devices-up {
            .footer-content {
                .contact-container {
                    width: 356px;
                    height: 206px;

                    .contact {
                        padding: 2.5rem 1rem 2rem 3rem;
                    }
                }
            }

            .bottom {
                left: 10%;
                width: 80%;

                .row {
                    gap: 0.75rem;
                }

                .made-with-love {
                    width: 23vw;
                    // height: 30px;
                    margin-left: 8rem;
                }

                p {
                    font-size: 0.75rem;
                }
            }
        }

        @include extra-large-devices-up {
            .bottom {
                left: 10%;
                width: 80%;

                .row {
                    gap: 1rem;
                }

                .made-with-love {
                    // height: 34px;
                }

                p {
                    font-size: 0.875rem;
                }
            }
        }

        @include extra-extra-large-devices-up {
            .bottom {
                left: 10%;
                width: 80%;

                .made-with-love {
                    // height: 20px;
                }
            }
        }
    }
}
